<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="输入活动名称" class="search-input" v-model="searchObj.keyword"></el-input>
          <el-select placeholder="选择状态" class="search-input ml-15" v-model="searchObj.status">
            <el-option v-for="(item,index) in groupby_status" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="addActivity">添加活动</el-button>
            </div>
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="活动序号">
              <template slot-scope="scope">{{scope.$index +1+ (pageSize*(pageNum-1))}}</template>
            </el-table-column>
            <el-table-column label="活动名称" prop="groupName"></el-table-column>
            <el-table-column label="活动时间" prop="beginTime">
              <template slot-scope="scope">{{scope.row.beginTime | dataFormat('yyyy-MM-dd hh:mm:ss')}}</template>
            </el-table-column>
            <el-table-column label="活动状态">
              <template slot-scope="scope">{{scope.row.status | statusFilter}}</template>
            </el-table-column>
            <el-table-column label="操作" width="400">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <!-- checkDetails: true addFlag: false,  表示不可编辑 表现型为div -->
                  <router-link :to="{name: 'Groupbuy_new', params: { id:scope.row.id,checkDetails:true,addFlag:false}}">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                  </router-link>
                  <router-link :to="{name: 'Groupbuy_orders', query: {id:scope.row.id}}" class="ml-10" v-if="scope.row.status!=0">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-info"></i>
                      查看订单
                    </el-link>
                  </router-link>
                  <router-link :to="{}" class="ml-10" v-if="scope.row.status!=0">
                    <el-link type="primary" :underline="false" @click="viewGoods(scope.row)">
                      <i class="el-icon-view"></i>
                      查看活动商品
                    </el-link>
                  </router-link>
                  <el-link type="danger" :underline="false" class="ml-10" v-if="scope.row.status!=2" @click="deleteGroupby(scope.row.id)">
                    <!-- <i class="el-icon-delete-solid"></i> -->
                    <i class="el-icon-setting"></i>
                    终止
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @syncPageData="syncPageData" />
        </div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="showGoods">
      <div class="pad-container">
        <el-table class="mt-24 member-table" :data="goodsTable">
          <el-table-column label="序号">
            <template slot-scope="scope">{{(scope.$index+1)+(goodsPageNum-1)*goodsPageSize}}</template>
          </el-table-column>
          <el-table-column label="商品名称">
            <template slot-scope="scope">{{scope.row.goodsInfo.goodsName}}</template>
          </el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <el-avatar :src="picsPath(scope.row.goodsInfo.imgUrl)" shape="square" alt="加载失败" :key="scope.row.goodsId"></el-avatar>
            </template>
          </el-table-column>
           <el-table-column label="所属门店">
            <template slot-scope="scope">{{scope.row.goodsInfo.storeName}}</template>
          </el-table-column>
          <el-table-column label="拼团价">
            <template slot-scope="scope">{{scope.row.activityPrice}}</template>
          </el-table-column>
          <el-table-column label="拼团销量" prop="sales">
            <template slot-scope="scope">{{scope.row.sale}}</template>
          </el-table-column>
          <el-table-column label="剩余活动库存" prop="kuchun">
            <template slot-scope="scope">{{scope.row.activityStock}}</template>
          </el-table-column>
        </el-table>
        <Pagination :total="goodsTotal" :pageNum="goodsPageNum" :pageSize="goodsPageSize" :pageSizes="goodsPageSizes" :hideSizes="true" :type="'dialog'" @syncPageData="syncPageData_goods" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { deleteGroup, getGroupList} from "@/api/maketing/groupbuy"
export default {
  name: "members_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        keyword: "",
        status: ""
      },
      // 拼团状态列表,用于筛选
      groupby_status: [
        { id: 0, name: "等待中" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" }
      ],
      // 拼团表格数据内容
      tableData: [],
      addFlag: true,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      showGoods: false,
      goodsList: [],
      goodsTable: [],
      goodsPageSize: 5,
      goodsPageSizes: [5, 10, 30],
      goodsPageNum: 1,
      goodsTotal: 0
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    //获取所有多人拼团
    initData(param = {}) {
      let status = String(this.searchObj.status);
      status == "null" ? (status = 3) : "";
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.searchObj.keyword,
        status
      };
      params = Object.assign(params, param);
      getGroupList(params).then(res => {
        // console.log(res.data.body)
        let data = res.data.body;
        this.total = data.total;
        this.tableData = data.list;
      })
    },
    // 添加活动
    addActivity() {
      sessionStorage.removeItem("CHECK_GROUP_DETAILS");
      this.$router.push({ name: "Groupbuy_new", params: { addFlag: true } });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        keyword: "",
        status: null
      };
      this.pageNum = 1;
      this.initData();
    },
    //删除拼团
    deleteGroupby(id) {
      this.$confirm("是否终止该活动？终止后无法再次启用！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteGroup(id).then(res => {
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.initData();
          });
        })
        .catch(() => { });
    },
    // 页面列表 pageNum pageSize获取
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
    // 商品列表 pageNum pageSize获取
    syncPageData_goods(data) {
      this.goodsPageNum = data.num;
      this.goodsPageSize = data.size;
      this.getPageGoods();
    },
    // 获取当前页面商品
    getPageGoods() {
      this.goodsTable = this.goodsList.slice(
        (this.goodsPageNum - 1) * this.goodsPageSize,
        (this.goodsPageNum - 1) * this.goodsPageSize + this.goodsPageSize
      );
    },
    // 查看商品列表
    viewGoods(data) {
      console.log(data)
      this.goods = data.list;
      this.goodsTotal = data.list.length;
      this.goodsList = data.list;
      this.getPageGoods();
      this.showGoods = true;
    }
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case 0:
          return "等待中";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "状态未知";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
